import styles from '../assets/css/skeletoncontent.module.css';

export const SkeletonContent = ({ style, className, children, loading }) => {

    return (
        <div style={style} className={[styles.container, loading ? styles.loading : null, className].join(' ').trim()}>
            <div className={styles.contentWrapper}>
                {children}
            </div>
            <div className={styles.skeleton}></div>
        </div>
    )

}

export default SkeletonContent;