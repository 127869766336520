import styles from '../../assets/css/views/login.module.css';

import Input from '../../components/Input';

import { PrimaryButton } from '../../components/Buttons';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const SecurityVerification = (props) => {

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const containerRef = useRef(null);
    const [verifying, setVerifying] = useState(false);

    const [error, setError] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [loginToken, setLoginToken] = useState(null);
    
    const next = searchParams.get('next');

    useEffect(() => {

        const sid = localStorage.getItem('session_id');
        const lt = localStorage.getItem('current');

        if (sid === null || sid === undefined || lt === null || lt === undefined) navigate(next ? '/login?' + new URLSearchParams({'next': next}) : '/login');
        
        setSessionId(sid);
        setLoginToken(lt);

    }, [navigate]);

    const verify = () => {

        const inputs = Array.from(containerRef.current.getElementsByTagName('input'));
        const code = `${inputs[0].value}${inputs[1].value}${inputs[2].value}${inputs[3].value}${inputs[4].value}${inputs[5].value}`;

        fetch('/api/v1/login', {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Login ${sessionId}.${loginToken}`
            },
            'body': JSON.stringify({
                'action': 'tfa',
                'code': code
            })
        }).then(res => res.json()).then(res => {

            setVerifying(false);
            switch (res.status){

                case 401:
                    setError(true);
                    break;
                case 200:
                    window.location.href = next ? next : '/';
                    break;
                default:
                    alert("Unexpected return value.");
            }

        });

    }

    const handleKeyDown = (e) => {
        if (!/[0-9]|Backspace|Tab|Enter|ArrowLeft|ArrowRight/.test(e.key)){
            if (!(e.ctrlKey && e.key === 'v')){
                e.preventDefault();
                return;
            }
        }
        const inputs = Array.from(e.target.parentElement.parentElement.parentElement.getElementsByTagName('input'));
        const current = e.target;
        const previous = inputs[inputs.indexOf(current) - 1];
        const next = inputs[inputs.indexOf(current) + 1];

        setTimeout(() => {

            const newValue = e.target.value;
            if (newValue.length === 1 || e.key === 'ArrowRight'){

                if (next){

                    next.focus();

                } else {

                    current.blur();
                    verify();

                }

            } else if (newValue.length === 0 || e.key === 'ArrowLeft'){

                if (previous) previous.focus();

            }

        }, 5);
    }

    const handlePaste = (e) => {

        e.preventDefault();
        const text = e.clipboardData.getData('Text');
        if (text.length === 6){

            const inputs = Array.from(containerRef.current.getElementsByTagName('input'));
            inputs[0].value = text[0];
            inputs[1].value = text[1];
            inputs[2].value = text[2];
            inputs[3].value = text[3];
            inputs[4].value = text[4];
            inputs[5].value = text[5];
            e.target.blur();

            verify();

        }

    }

    return <div className={styles.container}>
        <div className={styles.wrapper}>
            <img className={styles.logo} alt="" src="https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg" />
            <h1 className={styles.title}>{"Security Verification"}</h1>
            <div className={styles.subtitle}>{"Enter the 6-digit code from your two-factor authentication app."}</div>
            <form>
                <div className={styles.tfa} ref={containerRef}>
                    <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={verifying} onPaste={handlePaste} onFocus={() => setError(false)}/>
                    <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={verifying} onPaste={handlePaste} onFocus={() => setError(false)} />
                    <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={verifying} onPaste={handlePaste} onFocus={() => setError(false)} />
                    <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={verifying} onPaste={handlePaste} onFocus={() => setError(false)} />
                    <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={verifying} onPaste={handlePaste} onFocus={() => setError(false)} />
                    <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={verifying} onPaste={handlePaste} onFocus={() => setError(false)} />
                </div>
                <PrimaryButton loading={verifying} className={styles.button} label={"Verify"} onClick={(e) => {
                    verify();
                }}/>
            </form>
        </div>
    </div>

}

export default SecurityVerification;