import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../assets/css/sidebar.module.css'
import Logout from '../assets/svgs/Logout';
import MobileDevice from '../assets/svgs/MobileDevice';
import Personal from '../assets/svgs/Personal';
import Security from '../assets/svgs/Security';
import Connection from '../assets/svgs/Connection';

import Template from '../assets/svgs/Template';
import Burger from './Burger';
import { signOut } from '../assets/js/Api';
import Loader from './Loader';

export const Sidebar = (props) => {

    const location = useLocation();
    const [menuActive, setMenuActive] = useState(false);
    const [signingOut, setSigningOut] = useState(false);

    useEffect(() => {
        
        setMenuActive(false);

    }, [location]);

    return (
        <div className={[styles.sidebar, menuActive ? styles.active : null].join(' ').trim()}>
            <header className={styles.header}>
                <img className={styles.logo} alt={""} src={"https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg"}/>
                <div className={styles.title}><b>{"Xenfuma"}</b>{" Accounts"}</div>
                <Burger className={styles.burger} active={menuActive} onToggle={setMenuActive}/>
            </header>
            <nav className={styles.navigation}>
                <ul className={styles.items}>
                    <li className={[styles.item, location.pathname.substring(1).split('/')[0].length === 0 ? styles.selected : null].join(' ').trim()}>
                        <Link to={"/"} draggable="false">
                            <Template/>
                            <span>{"Overview"}</span>
                        </Link>
                    </li>
                    <li className={[styles.item, location.pathname.substring(1).split('/')[0] === 'general-info' ? styles.selected : null, styles['stroke-icon']].join(' ').trim()}>
                        <Link to={"/general-info"} draggable="false">
                            <Personal style={{strokeWidth: 5}}/>
                            <span>{"General Info"}</span>
                        </Link>
                    </li>
                    <li className={[styles.item, location.pathname.substring(1).split('/')[0] === 'security' ? styles.selected : null, styles['stroke-icon']].join(' ').trim()}>
                        <Link to={"/security"} draggable="false">
                            <Security/>
                            <span>{"Security"}</span>
                        </Link>
                    </li>
                    <li className={[styles.item, location.pathname.substring(1).split('/')[0] === 'devices' ? styles.selected : null].join(' ').trim()}>
                        <Link to={"/devices"} draggable="false">
                            <MobileDevice/>
                            <span>{"Devices"}</span>
                        </Link>
                    </li>
                    <li className={[styles.item, location.pathname.substring(1).split('/')[0] === 'connections' ? styles.selected : null].join(' ').trim()}>
                        <Link to={"/connections"} draggable="false">
                            <Connection stroke={'currentColor'}/>
                            <span>{"Connections"}</span>
                        </Link>
                    </li>
                </ul>
                <button className={styles.logout} onClick={() => {
                            setSigningOut(true);
                            signOut().then(() => window.location.href = '/login')
                    }} draggable="false">
                    <Logout/>
                    <span>{"Log Out"}</span>
                    { signingOut ? <>
                        <div className={styles.loader}>
                            <Loader size={20}/>
                        </div>
                    </> : null}
                </button>
            </nav>
        </div>
    )

}

export default Sidebar;