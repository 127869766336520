import styles from '../../assets/css/views/login.module.css';
import { Link, useSearchParams } from 'react-router-dom';
import QRCode from "react-qr-code";

import { io } from 'socket.io-client';
import { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import Config from '../../assets/js/Config';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

export const QRLogin = (props) => {

    const [searchParams] = useSearchParams();

    // eslint-disable-next-line
    const [ socket, setSocket ] = useState(null);
    const [ previousToken, setPreviousToken ] = useState(localStorage.getItem('qrl_session'));
    const [ currentToken, setCurrentToken ] = useState(null);
    const [ scanned, setScanned ] = useState(false);

    const next = searchParams.get('next');

    useEffect(() => {

        const sock = io();
        setSocket(sock);

        function onConnect(){
            sock.emit('qrl', {
                'action': 'connect',
                'previous': previousToken
            })
        }
        function onQRLUpdate(data){
            switch (data.action){
                case 'code_change':
                    setCurrentToken(data.code);
                    setPreviousToken(data.code);
                    const expires_at = data.expires_at - new Date().getTime() / 1000;
                    setTimeout(() => {

                        if (scanned) return;
                        sock.emit('qrl', {
                            'action': 'retrieve_code',
                            'previous': previousToken
                        })

                    }, (expires_at - 8) * 1000);
                    break
                case 'scanned':
                    setScanned(true);
                    break;
                case 'verified':
                    fetch(`${Config.API.endpoint}/api/v1/qrl`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            action: 'signin',
                            token: data.token,
                            session_id: localStorage.getItem('session_id')
                        })
                    }).then(res => res.json()).then(res => {
                        console.log(res)
                        if (res.status === 200){
                            localStorage.setItem('session_id', res.data.session);
                            localStorage.setItem('current', res.data.token);
                        } else if (res.status !== 409) return;
                        
                        window.location.href = next ? next : '/';
                    });
                    break;
                default:
                    alert(data.action);
            }
        }

        sock.on('connect', onConnect);
        sock.on('qrl', onQRLUpdate);

        return () => {
            sock.off('connect', onConnect);
            sock.off('qrl', onQRLUpdate);
            sock.disconnect();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className={styles.container}>
        <div className={styles.wrapper}>
            <img className={styles.logo} alt="" src="https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg"/>
            <h1 className={styles.title}>{"Sign In"}</h1>
            <div className={styles.subtitle}>{"Scan the QR Code below using the Xenfuma Accounts app to sign in."}</div>
            <div className={styles.qrcode}>
                <TransitionGroup component={null}>
                    <CSSTransition key={currentToken} appear timeout={4000} classNames={{
                        enter: styles['transition-enter'],
                        enterActive: styles['transition-enter-active'],
                        exit: styles['transition-exit'],
                        exitActive: styles['transition-exit-active']
                    }}>
                        {  currentToken && !scanned ? <>
                            <QRCode value={`${Config.API.endpoint}/qrl/${currentToken}`} size={166}/>
                        </> : <>
                            <Loader size={40} color={'black'} className={styles.qrloader}/>
                        </>}
                        
                    </CSSTransition>
                </TransitionGroup>
            </div>
            <div className={styles['sign-up']}>
                <span>{"Want to sign in normally?"}<Link to={"/login"}><span className={styles.clickable}>{"Go Back"}</span></Link></span>
            </div>
        </div>
    </div>

}

export default QRLogin;