import headerStyles from '../../assets/css/headers.module.css';
import { useConnections, useDevices, useIdentity } from '../../assets/js/Api';
import { LoginDevice } from '../../components/LoginDevice';
import { Section } from '../../components/Section';
import { SettingsItem } from '../../components/Settings';

export const Overview = (props) => {
    
    const identity = useIdentity();
    const devices = useDevices();
    const device = (devices ? devices : []).filter(d => d.current === true)[0];
    const connections = useConnections();

    const loading = devices === null || connections === null;

    return <>
        <h1 className={[headerStyles['header-1'], headerStyles['no-bottom-margin']].join(' ').trim()}>{"Overview"}</h1>
        <Section>
            <LoginDevice 
                thisDevice 
                operatingSystem={device ? device.meta.platform : null} 
                browser={device ? device.meta.browser : null} 
                lastLogin={new Date(device ? device.created_on : null)}
                type={
                    device ? device.meta.device_name : null || 
                    String(device ? device.meta.platform : null).toLowerCase() === 'android' || 
                    String(device ? device.meta.platform : null).toLowerCase() === 'ios' ? 'mobile' : ''}
                removable
                loading={loading}
            />
            <SettingsItem loading={loading} label={"General Info"} placeholder={"General information about you and your Xenfuma Account"} value={"General information about you and your Xenfuma Account"} link={"/general-info"}/>
            <SettingsItem loading={loading} label={"Security"} placeholder={"No security threats found!"} value={"No security threats found!"} link={"/security"}/>
            <SettingsItem loading={loading} label={"Devices"} placeholder={"0 Devices"} value={`${devices ? devices.length : ''} ${devices ? (devices.length === 1 ? 'Device' : 'Devices') : '-'}`} link={"/devices"}/>
            <SettingsItem loading={loading} label={"Connections"} placeholder={"0 Connections"} value={`${connections ? connections.length : ''} ${connections ? (connections.length === 1 ? 'Connection' : 'Connections') : '-'}`} link={"/connections"}/>
        </Section>
    </>

}

export default Overview;