import headerStyles from '../../assets/css/headers.module.css';
import { Section } from '../../components/Section';
import { Connection } from '../../components/Connection';
import { useConnections } from '../../assets/js/Api';
import { formatDate } from '../../assets/js/Utils';

export const Connections = (props) => {

    const connections = useConnections();

    return <>
        <h1 className={[headerStyles['header-1'], headerStyles['no-bottom-margin']].join(' ').trim()}>{"Connections"}</h1>
        <p>{"These are the apps that are currently authorized to access your Xenfuma Account"}</p>
        <Section>
            { (connections ? connections : []).map((c, i) => {
                return <Connection 
                    key={i}
                    id={c.id}
                    verified={c.verified} 
                    label={c.name} 
                    provider={c.provider.username} 
                    value={`Connected on the ${formatDate(new Date(c.connected_on))}`} 
                    icon={`${c.icon}`}
                    scopes={c.scopes}
                />
            }) } 
        </Section>
    </>

}

export default Connections;