import styles from '../assets/css/profile.module.css';

import { Loader } from './Loader';
import Logout from '../assets/svgs/Logout';
import Plus from '../assets/svgs/Plus';

import { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { removeAccount, setCurrentAccount, signOut, useSession } from '../assets/js/Api';

export const Profile = (props) => {

    const ref = useRef(null);
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const [signingOut, setSigningOut] = useState(false);
    const [switchingAccount, setSwitchingAccount] = useState(null);
    const [removingAccount, setRemovingAccount] = useState(null);

    const session = useSession();

    useEffect(() => {

        const clickOut = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener('click', clickOut, true);
        return () => {
            document.removeEventListener('click', clickOut, true);
        };
    }, []);

    if (session === null) return;

    return (
        <div ref={ref} className={[styles.profile, expanded ? styles.expanded : '', props.className].join(' ').trim()}>
            <img alt={''} onClick={() => {
                setExpanded(!expanded);
            }} className={styles.profile} src={true ? `https://cdn.xenfuma.com/avatars/${session.accounts[session.current].avatar}.png` : null}/>
            <div className={styles.options}>
                <div className={styles.current}>
                    <div className={styles['profile-picture']} style={{backgroundImage: true ? `url(https://cdn.xenfuma.com/avatars/${session.accounts[session.current].avatar}.png)` : null}}></div>
                    <div className={styles.details}>
                        <div className={styles.username}>{session.accounts[session.current].username}</div>
                        <div className={styles.email}>{session.accounts[session.current].email}</div>
                    </div>
                </div>
                <div className={styles.body}>
                    { Object.keys(session.accounts).length > 1 ? <>
                        <div className={styles.accounts}>
                            { Object.keys(session.accounts).filter(x => x !== session.current).map((id, key) => {
                                const account = session.accounts[id];
                                return <Account key={id} src={`https://cdn.xenfuma.com/avatars/${account.avatar}.png`} username={account.username} email={account.email} signedIn={account.authenticated} onClick={() => {
                                    if (switchingAccount || removingAccount) return;
                                    setSwitchingAccount(id);
                                    setCurrentAccount(session.id, account.token);
                                }} loading={switchingAccount === id || removingAccount === id} onRemove={() => {
                                    if (switchingAccount || removingAccount) return;
                                    setRemovingAccount(id);
                                    removeAccount(account.token).then(res => {
                                        setRemovingAccount(null);
                                        if (res.status === 200){
                                            delete session.accounts[id];
                                        }
                                    })
                                }}/>
                            }) }
                            <button className={styles.add} onClick={() => navigate('/login')}>
                                <div className={styles['icon-wrapper']}>
                                    <Plus/>
                                </div>
                                <span>{"Add another account"}</span>
                            </button>
                        </div>
                    </> : <></>}
                    
                    <div className={styles.actions}>
                        { Object.keys(session.accounts).length === 1 ? <>
                            <button className={styles.add} onClick={() => navigate('/login')}>
                                <div className={styles['icon-wrapper']}>
                                    <Plus/>
                                </div>
                                <span>{"Add another account"}</span>
                            </button>
                        </> : <></>}
                        <button className={styles.logout} draggable="false" onClick={() => {
                            setSigningOut(true);
                            signOut().then(() => window.location.href = '/login')
                        }}>
                            <div className={styles['icon-wrapper']}>
                                <Logout/>
                            </div>
                            <span>{"Log Out"}</span>
                            {signingOut ? <>
                                <div className={styles.loader}>
                                    <Loader size={20}/>
                                </div>
                            </> : null}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export const Account = ({ src, username, email, onClick, signedIn, loading, onRemove}) => {

    return (
        <div className={styles.account} onClick={() => {(onClick ? onClick : () => {})()}}>
            <button className={styles.click} aria-label={`Switch to account: ${username}`}></button>
            <div className={styles.content}>
                <div className={styles['profile-picture']} style={{backgroundImage: `url(${src})`}}></div>
                <div className={styles.details}>
                    <div className={styles.username}>{username}</div>
                    <div className={styles.email}>{signedIn ? email : 'Signed out'}</div>
                </div>
                { loading ? <>
                    <div className={styles.loader}>
                        <Loader size={20}/>
                    </div>
                </> : <>
                    <button className={styles.remove} onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        (onRemove ? onRemove : () => {})()
                    }}>
                        <Plus/>
                    </button>
                </>}    
            </div>
        </div>
    )
}

export const ProfileOption = (props) => {

    return (
        <div onClick={() => {
            
            if (!props.loading){
                props.onClick();
            }

        }} className={styles.option}>
            <div className={styles.label}>{props.label}</div>
            {props.loading ? <Loader className={styles.loader}/> : <></>}
        </div>
    )

}