import styles from '../../assets/css/views/login.module.css';
import { Link } from 'react-router-dom';

export const ResetPasswordSent = (props) => {

    return <div className={styles.container}>
        <div className={styles.wrapper}>
            <img className={styles.logo} alt="" src="https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg"/>
            <h1 className={styles.title}>{"Check your email"}</h1>
            <div className={[styles.subtitle, styles['no-spacing']].join(' ').trim()}>{"We've sent a password reset link to your email."}</div>
            <div className={styles['sign-up']}>
                <span><Link to={"/login"}><span className={styles.clickable}>{"Go Back"}</span></Link></span>
            </div>
        </div>
    </div>

}

export default ResetPasswordSent;