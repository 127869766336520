import styles from '../../assets/css/views/login.module.css';

import {
    Login,
    Register,
    Authorize,
    QRLogin,
    ResetPassword,
    ResetPasswordSent,
    SecurityVerification,
    Verify,
    Confirmed
} from '../Login';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Routes, Route, useLocation } from 'react-router-dom';

export const Base = (props) => {
 
    const location = useLocation();

    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.pathname} appear timeout={400} classNames={{
                enter: styles['transition-enter'],
                enterActive: styles['transition-enter-active'],
                exit: styles['transition-exit'],
                exitActive: styles['transition-exit-active']
            }}>
                <Routes location={location}>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/qrl" element={<QRLogin/>}/>
                    <Route path="/tfa" element={<SecurityVerification/>}/>
                    <Route path="/verify" element={<Verify/>}/>
                    <Route path="/authorize" element={<Authorize/>}/>
                    <Route path="/confirm/:token" element={<Confirmed/>}/>
                    <Route path="/reset">
                        <Route index element={<ResetPassword/>}></Route>
                        <Route path={"sent"} element={<ResetPasswordSent/>}></Route>
                    </Route>
                </Routes>
            </CSSTransition>
        </TransitionGroup>)

}

export default Base;