import headerStyles from '../../assets/css/headers.module.css';
import { Section } from '../../components/Section';
import { SettingsItem } from '../../components/Settings';

import { ChangeEmail, ChangePassword, ChangeUsername, Avatar } from './Dialogs';
import { useStore } from '../../assets/js/Store';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useIdentity } from '../../assets/js/Api';
import { formatDate } from '../../assets/js/Utils';

export const GeneralInformation = (props) => {

    const setDialog = useStore((state) => state.setDialog);
    const { dialog } = useParams();
    const navigate = useNavigate();
    const identity = useIdentity();
    const avatarRef = useRef(null);

    const loading = identity === null;

    useEffect(() => {

        switch (dialog){

            case 'username':
                setDialog(<ChangeUsername close={() => {
                    setDialog(null);
                    navigate('/general-info');
                }}/>);
                break;

            case 'email':
                setDialog(<ChangeEmail close={() => {
                    setDialog(null);
                    navigate('/general-info');
                }}/>);
                break;

            case 'password':
                setDialog(<ChangePassword close={() => {
                    setDialog(null);
                    navigate('/general-info');
                }}/>);
                break;

            default:
                setDialog(null);

        }

    }, [dialog, setDialog, navigate]);

    return <>
        <input ref={avatarRef} type={'file'} accept=".png,.gif,.jpeg,.jpg" style={{display: 'none'}} id={'avatar-input'} onChange={(e) => {
            const file = e.target.files[0];
            setDialog(<Avatar subtitle={'Not implemented yet'} close={() => {
                setDialog(null);
                navigate('/general-info');
            }} image={file}/>);
        }}/>
        <h1 className={[headerStyles['header-1'], headerStyles['no-bottom-margin']].join(' ').trim()}>{"General Information"}</h1>
        <p>{"Information about you and your Xenfuma Account"}</p>
        <Section>
            <SettingsItem loading={loading} placeholder={"Click to change your profile picture"} onClick={() => avatarRef.current.click()} label={"Profile Picture"} value={"Click to change your profile picture"}/>
            <SettingsItem loading={loading} placeholder={"Username"} link={'/general-info/username'} label={"Username"} value={identity ? identity.username : '-'}/>
            <SettingsItem loading={loading} placeholder={"example@example.com"} link={'/general-info/email'} label={"Email Address"} value={identity ? identity.email : '-'}/>
            <SettingsItem loading={loading} placeholder={"Last changed on the ---- of ----, ----"} link={"/general-info/password"} label={"Password"} value={`Last changed on the ${identity ?  formatDate(new Date(identity.password_changed_on)) : null}`}/>
        </Section>
    </>

}

export default GeneralInformation;