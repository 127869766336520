import styles from '../assets/css/connections.module.css';
import { SecondaryButton } from './Buttons';
import Check from '../assets/svgs/Check';
import Scopes, { Icons, Descriptions } from '../assets/js/Scopes';
import { useState } from 'react';
import { fetchConnections, removeConnection } from '../assets/js/Api';
import { useStore } from '../assets/js/Store';

export const Connection = ({ id, label, value, icon, provider, verified, scopes }) => {

    const [ loading, setLoading ] = useState(false);
    const setConnections = useStore((state) => state.setConnections);

    return <>
        <div className={styles.item}>
            <div className={styles.header}>
                <img alt="" src={icon} />
                <div className={styles.content}>
                    <div className={styles.label}>{label}{verified ? <div className={styles.verified}><Check /></div> : null}</div>
                    <div className={styles.value}>{provider}<span className={styles.divider}>{' • '}</span>{value}</div>
                </div>
            </div>
            <div className={styles.details}>
                <div className={styles.title}>{label}{' is allowed to:'}</div>
                <div className={styles.permissions}>
                    {(scopes ? scopes : []).map((s,i) => {

                        const scope = Object.keys(Scopes).find(key => Scopes[key] === s);

                        return (
                            <div className={styles.permission} key={i}>
                                <div className={styles.icon}>
                                    {Icons[scope]}
                                </div>
                                <div className={styles.label}>{Descriptions[scope]}</div>
                            </div>
                        )
                    })}
                    <SecondaryButton loading={loading} className={styles.disconnect} label={"Disconnect"} onClick={() => {
                        setLoading(true);
                        removeConnection(id).then(res => {
                            setLoading(false);
                            if (res.status === 200){
                                fetchConnections().then(connections => {
                                    setConnections(connections.data);
                                });
                            }
                        })
                    }}/>
                </div>
            </div>
        </div>
    </>

}