import { useEffect, useState } from 'react';
import styles from '../../../assets/css/dialogs/avatar.module.css';
import dStyles from '../../../assets/css/dialog.module.css';
import { useStore } from '../../../assets/js/Store';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import { fetchSession, setAvatar } from '../../../assets/js/Api';

import { Cropper } from '../../../components/Cropper';

export const Avatar = ({ image, close }) => {

    const [avatar, setAvatarImage] = useState(null);

    const setIdentity = useStore((state) => state.setIdentity);
    const setSession = useStore((state) => state.setSession);
    const identity = useStore((state) => state.identity);

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const closeFunction = useStore((state) => state.closeDialog);
    
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (closeFunction !== undefined) setCloseDialog(closeFunction);

    }, [setCloseDialog, close, closeFunction]);

    return ( 
        <div className={[dStyles.dialog, styles.dialog].join(' ').trim()}>
            <h2>{`Change Avatar`}</h2>
            <Cropper image={image} onChange={(data) => setAvatarImage(data)}/>
            <div className={styles.footer}>
                <div className={dStyles['button-tray']}>
                    <SecondaryButton className={dStyles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)} disabled={loading}/>
                    <PrimaryButton className={dStyles.button} label={'Save'} loading={loading} onClick={() => {
                        setLoading(true);
                        if (!avatar) return;
                        setAvatar(avatar).then(async (res) => {
                            if (res.status === 200) {
                                const session = await fetchSession();
                                setIdentity({...identity, avatar: res.data.avatar});
                                setSession(session);
                            }
                            setDialog(null);
                        }).catch(err => {
                            alert(err);
                            setLoading(false);
                        });
                    }}/>
                </div>
            </div>
        </div>
    )

}

export default Avatar;