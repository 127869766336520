import { Link } from 'react-router-dom';
import styles from '../assets/css/settings.module.css';
import SkeletonContent from './SkeletonContent';

export const SettingsItem = ({label, value, link, placeholder, loading, onClick}) => {

    return <>
        { link ? <>
            <Link to={link} className={styles.wrapper} draggable={false}>
                <div className={styles.item}>
                    <div className={styles.label}>{label}</div>
                    <SkeletonContent loading={loading}>
                        <div className={styles.value}>{loading ? placeholder : value}</div>
                    </SkeletonContent>
                </div>
            </Link>
        </> : <>
            <div className={styles.wrapper} draggable={false} onClick={onClick} style={onClick ? {cursor: 'pointer'} : null}>
                <div className={styles.item}>
                    <div className={styles.label}>{label}</div>
                    <SkeletonContent loading={loading}>
                        <div className={styles.value}>{loading ? placeholder : value}</div>
                    </SkeletonContent>
                </div>
            </div>
        </>}
    </>

}