import { useEffect, useState } from 'react';
import styles from '../../../assets/css/dialogs/tfa.module.css';
import dStyles from '../../../assets/css/dialog.module.css';
import { useStore } from '../../../assets/js/Store';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import QRCode from 'react-qr-code';
import Loader from '../../../components/Loader';
import { fetchTFASecretKey, enableTFA, fetchIdentity } from '../../../assets/js/Api';
import SecurityCode from '../../../components/SecurityCode';
import { Error } from '../../account/dialogs/Error'
import { useNavigate } from 'react-router-dom';

export const EnableTFA = (props) => {

    const navigate = useNavigate();

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const setIdentity = useStore((state) => state.setIdentity);
    const close = useStore((state) => state.closeDialog);

    const [secretKey, setSecretKey] = useState(null);
    const [error, setError] = useState(false);
    const [code, setCode] = useState('');
    const [verifying, setVerifying] = useState(false);

    useEffect(() => {

        fetchTFASecretKey().then(res => {
            if (res.data)
                setSecretKey(res.data.uri)
            else
                setDialog(<Error subtitle={'TFA is already enabled.'} close={() => {
                    setDialog(null);
                    navigate('/security')
                }}/>);
        });

    }, [navigate, setDialog]);

    useEffect(() => {

        if (props.close !== undefined) setCloseDialog(props.close);

    }, [setCloseDialog, close, props.close]);

    const verify = (code) => {
        setVerifying(true);
        enableTFA(code).then(res => {
            if (res.status === 200){
                fetchIdentity().then(res => {
                    setIdentity(res.data);
                    close();
                    setVerifying(false);
                });
            }
            else
            {
                setVerifying(false);
                setError(true);
            }
        });
    }

    return ( 
        <div className={[styles.dialog, dStyles.dialog].join(' ').trim()}>
            <h2>{`Enable Two-factor authentication`}</h2>
            <p className={dStyles.subtitle}>{'Scan the QR code with your authenticator app to continue with the setup.'}</p>
            <div className={styles['code-container']}>
                { secretKey ? <>
                    <QRCode className={styles.qr} value={secretKey} size={166}/>
                </> : <>
                    <Loader size={40} color={'black'}/>
                </>}
                
            </div>
            <div className={styles.footer}>
                <p className={[dStyles.subtitle, styles['code-message']].join(' ').trim()}>{'Enter the 6-digit code from your authenticator app.'}</p>
                <SecurityCode 
                    onChange={c => setCode(c)}
                    onVerify={e => verify(e)}    
                    error={error}
                />
                <div className={dStyles['button-tray']}>
                    <SecondaryButton className={dStyles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)} disabled={verifying}/>
                    <PrimaryButton className={dStyles.button} label={'Enable'} onClick={() => {
                        verify(code)
                    }} loading={verifying}/>
                </div>
            </div>
        </div>
    )

}

export default EnableTFA;