import styles from '../assets/css/sections.module.css';

export const Section = ({children, className, label}) => {

    return (
    <>
        { label ? <div className={styles.label}>{label}</div> : null}
        <div className={[styles.section, className].join(' ').trim()}>
            {children}
        </div>
    </>)

}