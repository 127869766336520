import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useStore = create((set) => ({

    dialog: null,
    closeDialog: null,
    setDialog: ( dialog ) => set({ dialog: dialog }),
    setCloseDialog: ( closeDialog ) => set({ closeDialog: closeDialog }),

    identity: null,
    setIdentity: ( identity ) => set({ identity: identity }),

    session: null,
    setSession: ( session ) => set({ session: session }),

    devices: null,
    setDevices: ( devices ) => set({ devices: devices }),

    connections: null,
    setConnections: ( connections ) => set({ connections: connections })

}))

if (process.env.NODE_ENV === 'development') { mountStoreDevtool('Store', useStore); }
