import Personal from "../svgs/Personal";
import Developer from '../svgs/Developer';
import Connect from '../svgs/Connect';

const Scopes = {
    'ACCOUNT_INFO_READONLY': 'account.info:readonly',
    'ACCOUNT_INFO_READWRITE': 'account.info:readwrite',
    'ACCOUNT_INFO_EMAIL_READONLY': 'account.info.email:readonly',
    'ACCOUNT_CONNECTIONS_READONLY': 'account.connections:readonly',
    'ACCOUNT_CONNECTIONS_READWRITE': 'account.connections:readwrite',
    'CONNECT_DEVICES_READONLY': 'connect.devices:readonly',
    'CONNECT_DEVICES_READWRITE': 'connect.devices:readwrite',
    'DEVELOPER_PROJECTS_READONLY': 'developer.projects:readonly',
    'DEVELOPER_PROJECTS_READWRITE': 'developer.projects:readwrite',
    'APP_PREFERENCES_READWRITE': 'app.preferences:readwrite'
}

export const Icons = {
    'ACCOUNT_INFO_READONLY': <Personal style={{ width: '17px', strokeWidth: '4.5px' }} />,
    'ACCOUNT_INFO_READWRITE': <Personal style={{ width: '17px', strokeWidth: '4.5px' }} />,
    'ACCOUNT_INFO_EMAIL_READONLY': <Personal style={{ width: '17px', strokeWidth: '4.5px' }} />,
    'ACCOUNT_CONNECTIONS_READONLY': <Personal style={{ width: '17px', strokeWidth: '4.5px' }} />,
    'ACCOUNT_CONNECTIONS_READWRITE': <Personal style={{ width: '17px', strokeWidth: '4.5px' }} />,
    'CONNECT_DEVICES_READONLY': <Connect/>,
    'CONNECT_DEVICES_READWRITE': <Connect/>,
    'DEVELOPER_PROJECTS_READONLY': <Developer/>,
    'DEVELOPER_PROJECTS_READWRITE': <Developer/>,
    'APP_PREFERENCES_READWRITE': <Developer/>
}

export const Descriptions = {
    'ACCOUNT_INFO_READONLY': 'View general information about your account',
    'ACCOUNT_INFO_READWRITE': 'View and change general information about your account',
    'ACCOUNT_INFO_EMAIL_READONLY': 'View your email address',
    'ACCOUNT_CONNECTIONS_READONLY': 'View your account connections',
    'ACCOUNT_CONNECTIONS_READWRITE': 'View and remove account connections',
    'CONNECT_DEVICES_READONLY': 'View your Xenfuma Connect devices',
    'CONNECT_DEVICES_READWRITE': 'View and remove your Xenfuma Connect devices',
    'DEVELOPER_PROJECTS_READONLY': 'View your developer projects',
    'DEVELOPER_PROJECTS_READWRITE': 'View and manage your developer projects',
    'APP_PREFERENCES_READWRITE': 'Access and customize its own settings in your account',
}

export default Scopes;