import { useEffect } from 'react';
import styles from '../../../assets/css/dialog.module.css';
import { useStore } from '../../../assets/js/Store';
import { DangerButton, SecondaryButton } from '../../../components/Buttons';
import { fetchDevices, removeDevice } from '../../../assets/js/Api';
import { Error } from '../Dialogs';

export const RemoveDevice = (props) => {

    const setDialog = useStore((state) => state.setDialog);
    const setDevices = useStore((state) => state.setDevices);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const close = useStore((state) => state.closeDialog);

    useEffect(() => {

        if (props.close !== undefined) setCloseDialog(props.close);

    }, [setCloseDialog, close, props.close]);

    return (
        <div className={styles.dialog}>
            <h2>{`Remove Device`}</h2>
            <p className={styles.subtitle}>{'Are you sure you want to sign out of the selected device?'}</p>
            { props.message ? <p>{props.message}</p> : null }
            <div className={styles['button-tray']}>
                <SecondaryButton className={styles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)}/>
                <DangerButton className={styles.button} label={'Remove'} onClick={close ? close : () => {
                    removeDevice(props.id).then(res => {
                        if (res.status === 200){
                            fetchDevices().then(res => {
                                if (res.status === 401){
                                    window.location.reload();
                                } else {
                                   setDevices(res.data);
                                    setDialog(null); 
                                }
                            })
                        } else 
                            setDialog(<Error/>)
                    })
                }}/>
            </div>
        </div>
    )

}

export default RemoveDevice;