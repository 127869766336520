import { useRoutes } from "react-router-dom";

import { Base as AccountBase } from '../views/Account';
import { Base as LoginBase} from '../views/Login';

export const Router = (props) => {
    const router = useRoutes([
        {
            element: <AccountBase />,
            path: '/*',
            children: [
               { index: true, element: null },
               { path: "security", element: null },
               { path: "general-info", element: null },
               { path: "devices", element: null },
               { path: "connections", element: null },
            ],
        },
        {
            element: <LoginBase />,
            path: '/*',
            children: [
                { path: "login", element: null },
                { path: "register", element: null },
                { path: "qrl", element: null },
                { path: "tfa", element:null },
                { path: "verify", element: null },
                { path: "authorize", element: null },
                { path: "reset", element: null },
                { path: "confirm/:token", element: null },
                { path: "reset/sent", element: null },
            ],
        },
    ]);
    return router;
}
