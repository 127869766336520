import styles from '../assets/css/device.module.css';

import MobileDevice from '../assets/svgs/MobileDevice.js';
import Computer from '../assets/svgs/Computer.js';
import Cross from '../assets/svgs/Cross.js';
import { useEffect, useState } from 'react';
import Check from '../assets/svgs/Check';
import { useStore } from '../assets/js/Store';
import { RemoveDevice } from '../views/account/Dialogs';
import SkeletonContent from './SkeletonContent';

const timeMapping = {
    SECOND: 1,
    MINUTE: 1 * 60,
    HOURS : 1 * 60 * 60,
    DAYS  : 1 * 60 * 60 * 24
}

export const LoginDevice = ({id, operatingSystem, browser, lastLogin, deviceName, thisDevice, type, removable, onRemove, loading}) => {

    const [time, setTime] = useState(null);
    const setDialog = useStore((state) => state.setDialog);

    useEffect(() => {

        const update = () => {

            let dif = Math.floor((new Date().getTime() - (lastLogin.getTime())) / 1000);

            const days = Math.floor(dif / timeMapping.DAYS);
            dif %= timeMapping.DAYS;
            const hours = Math.floor(dif / timeMapping.HOURS);
            dif %= timeMapping.HOURS;
            const minutes = Math.floor(dif / timeMapping.MINUTE);
            dif %= timeMapping.MINUTE;
    
            if (days === 0 && hours === 0 && minutes === 0){

                setTime(`${dif} second${dif !== 1 ? 's' : ''} ago`);

            } else if (days === 0 && hours === 0) {

                setTime(`${minutes} minute${minutes !== 1 ? 's' : ''} ago`);

            } else if (days === 0) {

                setTime(`${hours} hour${hours !== 1 ? 's' : ''} ago`);

            } else {

                setTime(`${days} day${days !== 1 ? 's' : ''} ago`);

            }

        }

        const i = setInterval(() => {
            update();
        }, 500);
        update();

        return () => clearInterval(i);

    }, [lastLogin])

    return <>
        <div className={styles.device}>
            <div className={styles.icon}>
                <SkeletonContent className={styles.skeleton} loading={loading}>
                    { type === 'mobile' ? <>
                        <MobileDevice style={{height: '27px'}}/>
                    </> : <>
                        <Computer style={{height: '24px'}}/>
                    </>}
                </SkeletonContent>
            </div>
            <div className={styles.details}>
                <SkeletonContent loading={loading}>
                    <div className={styles.name}>{loading ? 'Device Name' : (deviceName ? deviceName : null)}{loading ? '' : operatingSystem && deviceName ? "  ·  " : null}{loading ? 'Operating System' : operatingSystem}{loading ? '' : browser && operatingSystem ? "  ·  " : ''}{loading ? '' : browser}</div>
                </SkeletonContent>
                <SkeletonContent className={styles.timestampSkeleton} loading={loading}>
                    <div className={styles.timestamp}>{loading ? '0 seconds ago' : time}</div>
                </SkeletonContent>
                { thisDevice ? <>
                    <div className={styles.this}>
                        <div className={styles.check}>
                            <Check/>
                        </div>
                        <div className={styles.label}>{"This Device"}</div>
                    </div>
                </> : null }
            </div>
            { removable ? <>
                <div className={styles.remove} onClick={() => {
                    (onRemove ? onRemove : () => {})();
                    setDialog(<RemoveDevice id={id}/>)
                }}>
                    <Cross/>
                </div>
            </> : null}
        </div>
    </>

}