import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import headerStyles from '../../assets/css/headers.module.css';
import { useStore } from '../../assets/js/Store';
import { Section } from '../../components/Section';
import { SettingsItem } from '../../components/Settings';
import { ChangeEmail, ChangePassword, ConfirmEmail, DisableTFA, EnableTFA } from './Dialogs';
import { useConnections, useDevices, useIdentity } from '../../assets/js/Api';
import { formatDate } from '../../assets/js/Utils';

export const Security = (props) => {

    const setDialog = useStore((state) => state.setDialog);
    const { dialog } = useParams();
    const navigate = useNavigate();
    const devices = useDevices();
    const identity = useIdentity();
    const connections = useConnections();

    const loading = devices === null || identity === null || connections === null;

    useEffect(() => {

        switch (dialog){

            case 'password':

                setDialog(<ChangePassword close={() => {
                    setDialog(null);
                    navigate('/security');
                }} save={(context) => {

                    console.log(context);

                }}/>);
                break;

            case 'tfa':

                if (identity && identity.tfa_enabled){
                    setDialog(<DisableTFA close={() => {
                        setDialog(null);
                        navigate('/security');
                    }}/>);
                } else {
                    setDialog(<EnableTFA close={() => {
                        setDialog(null);
                        navigate('/security');
                    }}/>);
                }
                break;

            case 'email':

                setDialog(<ChangeEmail close={() => {
                    setDialog(null);
                    navigate('/security');
                }}/>);
                break;

            case 'confirm':

                setDialog(<ConfirmEmail close={() => {
                    setDialog(null);
                    navigate('/security');
                }}/>);
                break;

            default:

                setDialog(null);

        }

    }, [dialog, setDialog, navigate, identity]);

    return <>
        <h1 className={[headerStyles['header-1'], headerStyles['no-bottom-margin']].join(' ').trim()}>{"Security"}</h1>
        <p>{"Settings and information related to the security of your Xenfuma Account"}</p>
        <Section>
            <SettingsItem loading={loading} label={"Password"} placeholder={"Last changed on the ---- of ----, ----"} value={`Last changed on the ${identity ?  formatDate(new Date(identity.password_changed_on)) : null}`} link={"/security/password"}/>
            <SettingsItem loading={loading} label={"Two Factor Authentication"} placeholder={"Disabled"} value={identity ?  (identity.tfa_enabled ? "Enabled" : "Disabled") : '-'} link={"/security/tfa"}/>
            <SettingsItem loading={loading} label={"Devices"} placeholder={"0 Devices"} value={`${devices ? devices.length : ''} ${devices ? (devices.length === 1 ? 'Device' : 'Devices') : '-'}`} link={"/devices"}/>
            <SettingsItem loading={loading} label={"Connections"} placeholder={"0 Connections"} value={`${connections ? connections.length : ''} ${connections ? (connections.length === 1 ? 'Connection' : 'Connections') : '-'}`} link={"/connections"}/>
            <SettingsItem loading={loading} label={"Email Address"} placeholder={"example@example.com"} value={identity ? identity.email : '-'} link={"/security/email"}/>
            <SettingsItem loading={loading} label={"Email Address Confirmed"} placeholder={"Confirmed on the ---- of ----, ----"} value={identity && identity.confirmed_on ? `Confirmed on the ${identity ? formatDate(new Date(identity.confirmed_on)) : null}` : 'Not confirmed'} 
            {...(identity && identity.confirmed_on === null ? {link: "/security/confirm"} : {})}/>
        </Section>
    </>

}

export default Security;
