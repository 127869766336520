import styles from '../../assets/css/views/login.module.css';

import Input from '../../components/Input';

import { PrimaryButton } from '../../components/Buttons';

import Checkbox from '../../components/Checkbox';
import QRCode from '../../assets/svgs/QRCode';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export const Login = (props) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [identifier, setIdentifier] = useState(null);
    const [password, setPassword] = useState(null);
    const [trustDevice, setTrustDevice] = useState(false);

    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({
        'identifier': null,
        'password': null,
        'code': null
    });

    const next = searchParams.get('next');
    const known = searchParams.get('k') === '1';

    return <div className={styles.container}>
        <div className={styles.wrapper}>
            <img className={styles.logo} alt="" src="https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg"/>
            <h1 className={styles.title}>{"Sign In"}</h1>
            <div className={styles.subtitle}>{known ? "Welcome back! Enter your password below to sign back into your account." : "Welcome back! Enter your email and password below to sign in."}</div>
            { known ? <>
                <div className={styles.profile}>
                    <img className={styles.icon} alt="" src={"https://cdn.xenfuma.com/avatars/LDR5eyz0RfelYeQlrobjf3U6h3eVNP0N.png"}/>
                    <div className={styles.email}>{"marchauck04@gmail.com"}</div>
                </div>
            </> : <></>}
            { known ? <>
                <form>
                    <Input placeholder={"Password"} type={"password"} onChange={(value) => setPassword(value)} error={errors.password} onFocus={() => setErrors({})}/>
                    <div className={styles['password-extra']}>
                        <div className={styles['remember-device']}>
                            <Checkbox label={"Trust this device"} onChange={(value) => setTrustDevice(value)}/>
                        </div>
                        <Link to={"/reset"}><div className={styles['forgot-password']}>{"Forgot password"}</div></Link>
                    </div>
                    <PrimaryButton className={styles.button} label={"Sign In"} onClick={(e) => {
                        setLoading(true);
                        setTimeout(() => {
                            navigate('/')
                        }, 3000);
                    }}/>
                </form>
                <div className={styles['sign-up']}>
                    <span>{"Not your account?"}<Link to={"/login"}><span className={styles.clickable}>{"Go Back"}</span></Link></span>
                </div>
            </> : <>
                <form>
                    <Input placeholder={"Email"} type={"email"} onChange={(value) => setIdentifier(value)} error={errors.identifier} onFocus={() => setErrors({})}/>
                    <Input placeholder={"Password"} type={"password"} onChange={(value) => setPassword(value)} error={errors.password} onFocus={() => setErrors({})}/>
                    <div className={styles['password-extra']}>
                        <div className={styles['remember-device']}>
                            <Checkbox label={"Trust this device"} onChange={(value) => setTrustDevice(value)}/>
                        </div>
                        <Link to={"/reset"}><div className={styles['forgot-password']}>{"Forgot password"}</div></Link>
                    </div>
                    <PrimaryButton loading={loading} className={styles.button} label={"Sign In"} onClick={(e) => {
                        setLoading(true);
                        const sessionId = localStorage.getItem('session_id');
                        fetch('/api/v1/login', {
                            'method': 'POST',
                            'headers': {
                                'Content-Type': 'application/json'
                            },
                            'body': JSON.stringify({
                                'action': 'signin',
                                'identifier': identifier,
                                'password': password,
                                'session_id': sessionId,
                                'trust_device': trustDevice
                            })
                        }).then(res => res.json()).then(res => {

                            setLoading(false);
                            switch (res.status){

                                case 409:
                                    setErrors({
                                        ...errors,
                                        identifier: 'You\'re already logged into this account.'
                                    });
                                    break;
                                case 404:
                                    setErrors({
                                        ...errors,
                                        identifier: 'No user with this email.'
                                    });
                                    break;
                                case 401:
                                    setErrors({
                                        ...errors,
                                        password: 'Invalid password.'
                                    });
                                    break;
                                case 200:
                                    localStorage.setItem('session_id', res.data.session);
                                    localStorage.setItem('current', res.data.token);
                                    if (res.data.next === 'confirm_tfa') { 
                                        navigate(next ? '/tfa?' + new URLSearchParams({'next': next}) : '/tfa');
                                    } else {
                                        window.location.href = next ? next : '/';
                                    }
                                    break;
                                default:
                                    alert("Unexpected return value.");
                            }

                        }).catch(err => {
                            setLoading(false);
                            alert(err);
                        });
                    }}/>
                </form>
                <div className={styles['sign-up']}>
                    <span>{"Don't have an account?"}<Link to={"/register"}><span className={styles.clickable}>{"Sign Up"}</span></Link></span>
                </div>
            </>}
            <div className={styles['qr-login']} onClick={() => navigate(next ? '/qrl?' + new URLSearchParams({'next': next}) : '/qrl')}>
                <QRCode/>
                <div className={styles.tooltip}>{"Sign in using a QR Code"}</div>
            </div>
        </div>
    </div>

}

export default Login;