import { useEffect, useState } from 'react';
import styles from '../../../assets/css/dialog.module.css';
import { useStore } from '../../../assets/js/Store';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import { Input } from '../../../components/Input';
import { fetchIdentity, patchIdentity } from '../../../assets/js/Api';

export const ChangePassword = (props) => {

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const setIdentity = useStore((state) => state.setIdentity);
    const close = useStore((state) => state.closeDialog);

    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        password: null,
        new_password: null,
        confirm_new_password: null
    });

    const [errors, setErrors] = useState({
        password: null,
        new_password: null,
        confirm_new_password: null
    })

    useEffect(() => {

        if (props.close !== undefined) setCloseDialog(props.close);

    }, [setCloseDialog, close, props.close]);

    return (
        <div className={styles.dialog}>
            <h2>{`Change Password`}</h2>
            <p className={styles.subtitle}>{'Enter a new password and your current password.'}</p>
            { props.message ? <p>{props.message}</p> : null }
            <div className={styles.content}>
                <Input error={errors.new_password} className={styles.input} placeholder={'New password'} type={'password'} onChange={password => {
                    setValues((state) => ({
                        ...state,
                        new_password: password
                    }));
                }} onFocus={() => setErrors((errors) => ({
                    ...errors,
                    confirm_new_password: null,
                    new_password: null
                }))}/>
                <Input error={errors.confirm_new_password} className={styles.input} placeholder={'Repeat new password'} type={'password'} onChange={password => {
                    setValues((state) => ({
                        ...state,
                        confirm_new_password: password
                    }));
                }} onFocus={() => setErrors((errors) => ({
                    ...errors,
                    confirm_new_password: null,
                    new_password: null
                }))}/>
                <Input error={errors.password} className={styles.input} placeholder={'Old password'} type={'password'} onChange={password => {
                    setValues((state) => ({
                        ...state,
                        password: password
                    }));
                }} onFocus={() => setErrors((errors) => ({
                    ...errors,
                    password: null
                }))}/>
            </div>
            <div className={styles['button-tray']}>
                <SecondaryButton disabled={loading} className={styles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)}/>
                <PrimaryButton loading={loading} className={styles.button} label={'Save'} onClick={async () => {
                    
                    if (values.new_password !== values.confirm_new_password) {

                        setErrors((errors) => ({
                            ...errors,
                            new_password: true,
                            confirm_new_password: 'Passwords do not match.'
                        }))
                        return;

                    }

                    setLoading(true);
                    const res = await patchIdentity({
                        password: values.password,
                        new_password: values.new_password
                    });
                    setLoading(false);
                    if (res.status === 200) {
                        
                        const identity = await fetchIdentity();
                        if (identity.data) setIdentity(identity.data);
                        close();

                    } else {

                        switch (res.error) {

                            case 'unauthorized':
                                setErrors((errors) => ({
                                    ...errors,
                                    password: 'Invalid password.'
                                }))
                                break;
                            case 'password_format':
                                setErrors((errors) => ({
                                    ...errors,
                                    new_password: res.message,
                                    confirm_new_password: true
                                }))
                                break;
                            default:
                                alert(res.error);

                        }
                    }
                }}/>
            </div>
        </div>
    )

}

export default ChangePassword;