import { useEffect, useState } from 'react';
import styles from '../../../assets/css/dialog.module.css';
import { useStore } from '../../../assets/js/Store';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import { Input } from '../../../components/Input';
import { fetchIdentity, patchIdentity } from '../../../assets/js/Api';

export const ChangeUsername = (props) => {

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const setIdentity = useStore((state) => state.setIdentity);
    const close = useStore((state) => state.closeDialog);

    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        username: null,
        password: null
    });
    const [errors, setErrors] = useState({
        username: null,
        password: null
    });

    useEffect(() => {

        if (props.close !== undefined) setCloseDialog(props.close);

    }, [setCloseDialog, close, props.close]);

    return (
        <div className={styles.dialog}>
            <h2>{`Change Username`}</h2>
            <p className={styles.subtitle}>{'Enter a new username and your current password.'}</p>
            { props.message ? <p>{props.message}</p> : null }
            <div className={styles.content}>
                <Input error={errors.username} className={styles.input} placeholder={'Username'} onChange={username => {
                    setValues((state) => ({
                        ...state,
                        username: username
                    }));
                }} onFocus={() => setErrors((errors) => ({
                    ...errors,
                    username: null
                }))}/>
                <Input error={errors.password} className={styles.input} placeholder={'Password'} type={'password'} onChange={password => {
                    setValues((state) => ({
                        ...state,
                        password: password
                    }));
                }} onFocus={() => setErrors((errors) => ({
                    ...errors,
                    password: null
                }))}/>
            </div>
            <div className={styles['button-tray']}>
                <SecondaryButton className={styles.button} disabled={loading} label={'Cancel'} onClick={close ? close : () => setDialog(null)}/>
                <PrimaryButton className={styles.button} loading={loading} label={'Save'} onClick={() => {
                    setLoading(true);
                    patchIdentity({
                        username: values.username,
                        password: values.password
                    }).then(async (res) => {
                        setLoading(false);
                        if (res.status === 200){
                            const identity = await fetchIdentity();
                            if (identity.data) setIdentity(identity.data);
                            close();
                        } else {
                            switch (res.error) {
                                case 'username_conflict':
                                    setErrors((state) => ({
                                        ...state,
                                        username: res.message
                                    }))
                                    break;
                                case 'invalid_password': 
                                    setErrors((state) => ({
                                        ...state,
                                        password: 'Invalid password.'
                                    }));
                                    break;
                                default:
                                    alert(res.error)
                            }
                        }
                    }).catch(e => {
                        alert(e);
                        setLoading(false);
                    })
                }}/>
            </div>
        </div>
    )

}

export default ChangeUsername;