import { useEffect, useState } from 'react';
import styles from '../../assets/css/views/login.module.css';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { confirmEmail } from '../../assets/js/Api';

export const Confirmed = (props) => {

    const { token } = useParams();
    const [tokenRes, setTokenRes] = useState(null);

    useEffect(() => {(async () => {
            
        const res = await confirmEmail(token);
        setTokenRes(res);

    })()}, [token])

    return tokenRes ? <>
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <img className={styles.logo} alt="" src="https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg"/>
                <h1 className={styles.title}>{tokenRes.error ? 'Error!' : 'Done!'}</h1>
                <div className={[styles.subtitle, styles['no-spacing']].join(' ').trim()}>{tokenRes.message}</div>
                <div className={styles['sign-up']}>
                    <span><Link to={"/login"}><span className={styles.clickable}>{"Sign In"}</span></Link></span>
                </div>
            </div>
        </div>
    </> : <>
        <Loader size={40} color={'black'} className={styles['page-loader']}/>
    </>

}

export default Confirmed;