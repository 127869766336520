import { useLocation } from 'react-router-dom';
import styles from '../../assets/css/views/account.module.css';
import dialogStyles from '../../assets/css/dialog.module.css';
import { Profile } from '../../components/Profile';
import Sidebar from '../../components/Sidebar';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Routes, Route } from 'react-router-dom';

import { Error } from '../Error';

import {
    Connections,
    Devices,
    GeneralInformation,
    Security,
    Overview
} from '../Account';
import { useStore } from '../../assets/js/Store';
import { useCallback } from 'react';
import { useSession } from '../../assets/js/Api';

export const Base = (props) => {

    const location = useLocation();

    const dialog = useStore((state) => state.dialog);
    const setDialog = useStore((state) => state.setDialog);
    const close = useStore((state) => state.closeDialog);
    const session = useSession();

    const contentRef = useCallback(node => {
        if (node !== null) document.body.setAttribute('style', `--window-height: ${window.innerHeight}px; --scrollbar-width: ${node.offsetWidth - node.clientWidth}px!important;`);
    }, [])

    if (!['', 'general-info', 'security', 'devices', 'connections'].includes(location.pathname.split('/')[1])) return <Error code={404} title={'Not Found!'} message={'We couldn\'t find the page you were looking for!'}/>

    if (session === null || session === null || session.id === null || session.id === undefined) return;

    return (
        <div className={[styles.container].join(' ').trim()}>
            <Sidebar/>
                <TransitionGroup component={null}>
                    <CSSTransition key={location.pathname.substring(1).split('/')[0]} appear timeout={400} classNames={{
                        enter: styles['transition-enter'],
                        enterActive: styles['transition-enter-active'],
                        exit: styles['transition-exit'],
                        exitActive: styles['transition-exit-active']
                    }}>
                        <div className={styles.content} ref={contentRef}>
                            <Routes location={location}>
                                <Route path={'/'} element={<Overview/>}/>
                                <Route path={'/general-info/:dialog?'} element={<GeneralInformation/>}/>
                                <Route path={'/security/:dialog?'} element={<Security/>}/>
                                <Route path={'/devices/:dialog?'} element={<Devices/>}/>
                                <Route path={'/connections/:dialog?'} element={<Connections/>}/>
                            </Routes>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            <Profile className={styles.profile}/>
            <TransitionGroup onMouseDown={(e) => {
                if (e.currentTarget !== e.target) return;
                (close !== undefined && close !== null ? close : setDialog)(null);
            }} className={[dialogStyles['dialog-wrapper'], dialog !== undefined && dialog !== null ? dialogStyles.visible : null].join(' ').trim()}>
                {dialog !== undefined && dialog !== null ? <CSSTransition appear timeout={300} classNames={{
                        enter: dialogStyles['transition-enter'],
                        enterActive: dialogStyles['transition-enter-active'],
                        exit: dialogStyles['transition-exit'],
                        exitActive: dialogStyles['transition-exit-active']
                    }}>
                        {dialog}
                    </CSSTransition> : null}
            </TransitionGroup>
        </div>
    )

}

export default Base;