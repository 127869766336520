import './assets/fonts/rubik/rubik.css';
import './assets/css/colors.css';
import './assets/css/index.css';

import { Router } from './components/Router';

function App() {
	return (
		<Router/>
	);
}

export default App;
