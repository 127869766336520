import { forwardRef, useEffect, useState } from 'react';
import styles from '../assets/css/inputs.module.css';

export const Input = forwardRef((props, ref) => {

    const [focused, setFocused] = useState(false);
    const [empty, setEmpty] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => setError(props.error), [props.error])

    return (
        <div className={[props.className, styles.container, focused ? styles.focused : null, error ? styles.error : null, !empty ? styles['not-empty'] : null].join(' ').trim()}>
            <div className={styles.wrapper}>
                <input 
                    onPaste={props.onPaste ? props.onPaste : () => {}} 
                    disabled={props.disabled} 
                    inputMode={props.inputMode} 
                    onKeyDown={props.onKeyDown ? props.onKeyDown : () => {}} 
                    type={props.type} 
                    maxLength={props.maxLength} 
                    ref={ref} 
                    onChange={(event) => {
                        setEmpty(event.currentTarget.value.length === 0);
                        (props.onChange ? props.onChange : () => {})(event.currentTarget.value);
                    }} 
                    onFocus={
                    () => {
                        setFocused(true);
                        setError(null);
                        (props.onFocus ? props.onFocus : () => {})()
                    }} 
                    onBlur={() => setFocused(false)}
                />
                <div className={styles.placeholder}>{props.placeholder ? props.placeholder : ''}</div>
            </div>
            { error !== undefined && error !== null && typeof error === 'string' ? <>
                <div className={[styles.error, error ? styles.visible : null].join(' ').trim()}>
                    <div className={styles.label}>{error}</div>
                </div>
            </> : <></>}
        </div>
    )
});

export default Input;