import { useEffect, useRef, useState } from 'react';
import styles from '../../../assets/css/dialog.module.css';
import { useStore } from '../../../assets/js/Store';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import { Input } from '../../../components/Input';
import { patchIdentity } from '../../../assets/js/Api';

export const ChangeEmail = (props) => {

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const close = useStore((state) => state.closeDialog);

    const [errors, setErrors] = useState({
        email: null,
        password: null
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (props.close !== undefined) setCloseDialog(props.close);

    }, [setCloseDialog, close, props.close]);

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    return (
        <div className={styles.dialog}>
            <h2>{`Change E-Mail`}</h2>
            <p className={styles.subtitle}>{'Enter a new email and your current password.'}</p>
            { props.message ? <p>{props.message}</p> : null }
            <div className={styles.content}>
                <Input ref={emailRef} className={styles.input} placeholder={'E-Mail'} type={'email'} error={errors.email} onFocus={() => setErrors((state) => ({
                    ...state,
                    email: null
                }))}/>
                <Input ref={passwordRef} className={styles.input} placeholder={'Password'} type={'password'} error={errors.password} onFocus={() => setErrors((state) => ({
                    ...state,
                    password: null
                }))}/>
            </div>
            <div className={styles['button-tray']}>
                <SecondaryButton disabled={loading} className={styles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)}/>
                <PrimaryButton loading={loading} className={styles.button} label={'Save'} onClick={(event) => {

                    let problem = false;

                    const email = emailRef.current.value;
                    const password = passwordRef.current.value;
                    
                    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {

                        setErrors((state) => ({
                            ...state,
                            email: 'Incorrect email'
                        }));
                        problem = true;

                    };

                    if (password === null || password === undefined || password.length === 0){

                        setErrors((state) => ({
                            ...state,
                            password: 'Enter your password'
                        }));
                        problem = true;

                    };

                    if (!problem) {

                        setLoading(true);
                        patchIdentity({
                            email: email,
                            password: password
                        }).then(res => {
                            if (res.status === 200){
                                close();
                            } else {
                                setLoading(false);
                                switch (res.error) {

                                    case 'invalid_password':

                                        setErrors((state) => ({
                                            ...state,
                                            password: 'Invalid password.'
                                        }));
                                        break;

                                    case 'invalid_email':

                                        setErrors((state) => ({
                                            ...state,
                                            email: 'Please provide a valid email address.'
                                        }));
                                        break;

                                    case 'email_conflict':

                                        setErrors((state) => ({
                                            ...state,
                                            email: res.message
                                        }));
                                        break;

                                }
                            }
                        })
                    };
                }}/>
            </div>
        </div>
    )

}

export default ChangeEmail;