import { useEffect, useState } from 'react';
import styles from '../../../assets/css/dialog.module.css';
import { useStore } from '../../../assets/js/Store';
import { DangerButton, SecondaryButton } from '../../../components/Buttons';
import { disableTFA, fetchIdentity } from '../../../assets/js/Api';
import Input from '../../../components/Input';

export const DisableTFA = (props) => {

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const setIdentity = useStore((state) => state.setIdentity);
    const close = useStore((state) => state.closeDialog);

    const [error, setError] = useState(null);
    const [verifying, setVerifying] = useState(false);
    const [password, setPassword] = useState(null);

    useEffect(() => {

        if (props.close !== undefined) setCloseDialog(props.close);

    }, [setCloseDialog, close, props.close]);

    return ( 
        <div className={[styles.dialog].join(' ').trim()}>
            <h2>{`Disable Two-factor authentication`}</h2>
            <p className={styles.subtitle}>{'Enter your password to continue. This will disable two-factor authentication.'}</p>
            <div className={styles.content}>
                <Input className={styles.oinput} placeholder={'Password'} type={'password'} error={error} onChange={p => setPassword(p)} onFocus={() => setError(null)}/>
            </div>
            <div className={styles['button-tray']}>
                <SecondaryButton className={styles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)} disabled={verifying}/>
                <DangerButton className={styles.button} label={'Disable'} onClick={() => {
                    setVerifying(true);
                    disableTFA(password).then(res => {
                        if (res.status === 200){
                            fetchIdentity().then(res => {
                                setIdentity(res.data);
                                close();
                                setVerifying(false);
                            });
                        } else {
                            setError(res.error === 'invalid_password' ? 'Invalid password.' : res.message);
                            setVerifying(false);
                        }
                    });
                }} disabled={verifying} loading={verifying}/>
            </div>
        </div>
    )

}

export default DisableTFA;