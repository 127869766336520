
import { useEffect, useRef, useState } from 'react';
import { useStore } from '../js/Store';
import { useNavigate } from 'react-router-dom';

const makeRequest = (url, options={}, session_id=null, login_token=null) => {

    session_id = session_id === null ? localStorage.getItem('session_id') : session_id;
    login_token = login_token === null ? localStorage.getItem('current') : login_token;

    if (options.headers === null || options.headers === undefined) options.headers = {};

    const sessionId = session_id;
    const currentLogin = login_token;

    const auth = `${sessionId}.${currentLogin}`;
    options.headers['Authorization'] = `Login ${auth}`;

    return new Promise((resolve, reject) => {

        fetch(url, options)
        .then((res) => res.json())
        .then((res) => resolve(res))
        .catch((res) => reject(res));

    });

}

export class Session {

    id = null;
    current = null;
    accounts = {}

}

export class Account {

    id = null;
    username = null;
    email = null;
    avatar = null;

}

export const fetchIdentity = (session_id=null, login_token=null) => makeRequest('/api/v1/identity');
export const fetchSession = () => makeRequest('/api/v1/session');
export const fetchDevices = () => makeRequest('/api/v1/devices');
export const fetchConnections = () => makeRequest('/api/v1/connections');
export const fetchTFASecretKey = () => makeRequest('/api/v1/security/tfa');
export const fetchConfirmEmailStatus = () => makeRequest('/api/v1/identity/confirm');

export const useIdentity = () => {

    const navigate = useNavigate();
    const session = useSession();

    const identity = useStore((state) => state.identity);
    const setIdentity = useStore((state) => state.setIdentity);

    useEffect(() => {

        if ((session !== null && session !== undefined) & (identity === null || identity === undefined)){

            fetchIdentity(session.id, session.current).then((res) => {

                if (res.status === 200){
                    setIdentity(res.data);
                } else {
                    window.location.replace('/login');

                }
                

            }).catch((res) => {

                
            })

        }

    }, [navigate, session, identity, setIdentity]);

    return identity;

}

export const useSession = () => {

    const navigate = useNavigate();

    const session = useStore((state) => state.session);
    const setSession = useStore((state) => state.setSession);

    const waitingRef = useRef(false);

    useEffect(() => {

        if (!waitingRef.current && ((session === null || session === undefined) || (session.current === null || session.current === undefined))){

            waitingRef.current = true;

            fetchSession().then((res) => {

                waitingRef.current = false;
                if (res.status === 200){
                    setSession(res.data);
                } else {
                    const next = window.location.href;
                    window.location.replace('/login?' + new URLSearchParams({'next': next}));
                }

            });

        }

    }, [waitingRef, navigate, session, setSession]);

    return session;

}

export const useDevices = () => {

    const navigate = useNavigate();

    const devices = useStore((state) => state.devices);
    const setDevices = useStore((state) => state.setDevices);

    useEffect(() => {

        if (devices === undefined || devices === null){

            fetchDevices().then((res) => {

                if (res.status === 200){
                    setDevices(res.data);
                } else {
                    window.location.replace('/login');
                }

            });

        }

    }, [devices, setDevices, navigate]);

    return devices;

}

export const useConnections = () => {

    const navigate = useNavigate();

    const connections = useStore((state) => state.connections);
    const setConnections = useStore((state) => state.setConnections);

    useEffect(() => {

        if (connections === undefined || connections === null){

            fetchConnections().then((res) => {

                if (res.status === 200){
                    setConnections(res.data);
                } else {
                    window.location.replace('/login');
                }

            });

        }

    }, [connections, setConnections, navigate]);

    return connections;

}

export const setCurrentAccount = (session_id, token) => {

    localStorage.setItem('current', token);
    makeRequest('/api/v1/session', {
        'method': 'PATCH',
        'headers': {
            'Authorization': `Login ${session_id}.${token}`,
            'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
            'action': 'set_current'
        })
    }).then(() => {
        window.location.reload();
    });

}

export const signOut = (session_id, token) => {

    return makeRequest('/api/v1/session', {
        'method': 'PATCH',
        'headers': {
            'Authorization': `Login ${session_id}.${token}`,
            'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
            'action': 'signout'
        })
    })

}

export const removeAccount = (account_token) => {

    return makeRequest('/api/v1/session', {
        'method': 'DELETE',
        'headers': {
            'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
            'token': account_token
        })
    })

}

export const fetchAuthScreen = async (client_id, response_type, redirect_uri, scope) => {

    const res = await makeRequest('/api/v1/oauth/authorize?' + new URLSearchParams({
        client_id: client_id,
        response_type: response_type,
        redirect_uri: redirect_uri,
        scope: scope
    }));
    return res;

}

export const useAuthScreen = (client_id, response_type, redirect_uri, scope) => {

    const [authScreen, setAuthScreen] = useState(null);

    useEffect(() => {

        (async () => {

            const res = await fetchAuthScreen(client_id, response_type, redirect_uri, scope);
            if (res.data) setAuthScreen(res.data);
            else setAuthScreen(res)

        })();

    }, [client_id, response_type, redirect_uri, scope])

    return authScreen;

}

export const authorizeOAuthApp = async (client_id, response_type, redirect_uri, scope, code_challenge, code_challenge_method, authorized) => {

    const res = await makeRequest('/api/v1/oauth/authorize', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            client_id: client_id,
            response_type: response_type,
            redirect_uri: redirect_uri,
            scope: scope,
            code_challenge: code_challenge,
            code_challenge_method: code_challenge_method,
            authorized: authorized
        })
    });

    return res;

}

export const enableTFA = async (code) => {

    const res = await makeRequest('/api/v1/security/tfa', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            code: code
        })
    });

    return res;

}

export const disableTFA = async (password) => {

    const res = await makeRequest('/api/v1/security/tfa', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            password: password
        })
    });

    return res;

}

export const removeDevice = async (device_id) => {

    const res = await makeRequest('/api/v1/devices', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: device_id
        })
    });

    return res;

}

export const removeConnection = async (connection_id) => {

    const res = await makeRequest('/api/v1/connections', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: connection_id
        })
    });

    return res;

}

export const patchIdentity = async (changes) => {

    const res = await makeRequest('/api/v1/identity', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(changes)
    });

    return res;

}

export const setAvatar = async (avatarDataUrl) => {

    const byteString = atob(avatarDataUrl.split(',')[1]);
    const mimeString = avatarDataUrl.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) 
        uintArray[i] = byteString.charCodeAt(i);

    const blob = new Blob([arrayBuffer], { type: mimeString });
    const data = new FormData();
    data.append('avatar', blob, 'avatar.png'); 

    const res = await makeRequest('/api/v1/identity', {
        method: 'PATCH',
        body: data
    });

    return res;

}

export const confirmEmail = async (token) => {

    const res = await makeRequest('/api/v1/identity/confirm', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token: token
        })
    });

    return res;

}