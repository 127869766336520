import styles from '../../assets/css/views/login.module.css';

import Input from '../../components/Input';

import { PrimaryButton } from '../../components/Buttons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export const Register = (props) => {

    const [searchParams] = useSearchParams();

    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(false);

    const [loading, setLoading] = useState(false);
    const next = searchParams.get('next');

    const [errors, setErrors] = useState({
        'username': null,
        'email': null,
        'password': null,
        'confirm_password': null
    });

    return <div className={styles.container}>
        <div className={styles.wrapper}>
            <img className={styles.logo} alt="" src="https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg"/>
            <h1 className={styles.title}>{"Sign Up"}</h1>
            <div className={styles.subtitle}>{"Create your Xenfuma Account"}</div>
            <form>
                <Input placeholder={"Username"} type={"text"} onChange={(value) => setUsername(value)} error={errors.username} onFocus={() => setErrors({...errors, username: null})}/>
                <Input placeholder={"Email"} type={"email"} onChange={(value) => setEmail(value)} error={errors.email} onFocus={() => setErrors({...errors, email: null})}/>
                <Input placeholder={"Password"} type={"password"} onChange={(value) => setPassword(value)} error={errors.password} onFocus={() => setErrors({...errors, password: null, confirm_password: null})}/>
                <Input placeholder={"Confirm Password"} onChange={(value) => setConfirmPassword(value)} type={"password"} error={errors.confirm_password} onFocus={() => setErrors({...errors, password: null, confirm_password: null})}/>
                <PrimaryButton loading={loading} className={styles.button} label={"Sign Up"} onClick={e => {
            
                        const sessionId = localStorage.getItem('session_id');
                        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gmi;

                        let error = false;
                        const errors = {
                            username: null,
                            email: null,
                            password: null,
                            confirm_password: null
                        }

                        if (username === null || username === undefined || username.length === 0) errors.username = 'Plase enter a username.';
                        if (email === null || email === undefined || email.length === 0) errors.email = 'Please enter an email address.';
                        if (password === null || password === undefined || password.length === 0) {errors.password = 'Please enter a password.'; errors.confirm_password = true;};
                        if (password && password.length > 0 && password !== confirmPassword) {errors.password = true; errors.confirm_password = 'Passwords do not match.'};
                        if (password && password.length < 8) errors.password = 'Password must be at least 8 characters long.';

                        setErrors({
                            username: errors.username,
                            email: errors.email,
                            password: errors.password,
                            confirm_password: errors.confirm_password
                        })

                        if (errors.username || errors.email || errors.password || errors.confirm_password) return;
                        setLoading(true);

                        fetch('/api/v1/login', {
                            'method': 'POST',
                            'headers': {
                                'Content-Type': 'application/json'
                            },
                            'body': JSON.stringify({
                                'action': 'signup',
                                'username': username,
                                'email': email,
                                'password': password,
                                'session_id': sessionId,
                            })
                        }).then(res => res.json()).then(res => {

                            setLoading(false);
                            switch (res.status){

                                case 409:
                                    const errors = {
                                        username: null,
                                        email: null,
                                        password: null
                                    }
                                    res.errors.forEach(e => {
                                        switch (e.error) {
                                            case 'invalid_email':
                                                errors.email = e.message;
                                                break
                                            case 'username_conflict':  
                                                errors.username = e.message;
                                                break;
                                            case 'email_conflict':
                                                errors.email = e.message;
                                                break;
                                            case 'password_format':
                                                errors.password = e.message;
                                                break;
                                            default:
                                                alert(JSON.stringify(e, '\t', 4))
                                        }
                                    });
                                    setErrors({
                                        username: errors.username,
                                        email: errors.email,
                                        password: errors.password
                                    })
                                    break;
                                case 200:
                                    localStorage.setItem('session_id', res.data.session);
                                    localStorage.setItem('current', res.data.token);
                                    window.location.href = next ? next : '/';
                                    break;
                                case 400:
                                    alert(res.message);
                                    break;
                                default:
                                    alert("Unexpected return value.");
                            }

                        }).catch(err => {
                            setLoading(false);
                            alert(err);
                        });
                    }}/>
            </form>
            <div className={styles['sign-in']}>
            <span>{"Already have an account?"}<Link to={"/login"}><span className={styles.clickable}>{"Sign In"}</span></Link></span>
            </div>
        </div>
    </div>

}

export default Register;