import { useEffect, useRef, useState } from 'react';
import styles from '../assets/css/cropper.module.css';
import Slider from './Slider';

export const Cropper = ({ className, image, onChange }) => {

    const [canvasPosition, setCanvasPosition] = useState({x: 0, y: 0});
    const [canvasOffset, setCanvasOffset] = useState({x: 0, y: 0});
    const [draggingCanvas, setDraggingCanvas] = useState(false);
    const [imageSize, setImageSize] = useState({width: 0, height: 0});
    const [originalImageSize, setOriginalImageSize] = useState({width: 0, height: 0});
    const [imageObject, setImageObject] = useState(null);
    const [overlaySize] = useState(350);
    const [scale, setScale] = useState(1);
    const [ratio, setRatio] = useState(1);

    const imageRef = useRef(null);
    const imageWrapperRef = useRef(null);
    const overlayRef = useRef(null);
    const areaRef = useRef(null);

    useEffect(() => {
        if (!image) return;
        const reader = new FileReader();
        reader.onload = function (e) {
            const base64ImageData = e.target.result;
            const img = new Image();
            img.src = base64ImageData;
            img.onload = () => {
                setImageObject({
                    size: {
                        width: img.naturalWidth,
                        height: img.naturalHeight
                    },
                    data: base64ImageData,
                    image: img
                });
                setOriginalImageSize({
                    width: img.naturalWidth,
                    height: img.naturalHeight
                });
            };
        };
        reader.readAsDataURL(image);
    }, [image]);

    useEffect(() => {
        const imageWidth = originalImageSize.width * ratio * scale;
        const imageHeight = originalImageSize.height * ratio * scale;
        setImageSize({
            width: imageWidth,
            height: imageHeight
        });
        const difX = imageSize.width - imageWidth;
        const difY = imageSize.height - imageHeight;

        if (imageSize.width === 0 && imageSize.height === 0) {
            setCanvasPosition({
                x: Math.min(Math.max(-(imageWidth / 2 - overlaySize / 2), -(imageWidth - overlaySize)), 0),
                y: Math.min(Math.max(-(imageHeight / 2 - overlaySize / 2), -(imageHeight - overlaySize)), 0),
            });
        } else {
            setCanvasPosition({
                x: Math.min(Math.max(canvasPosition.x + difX / 2, -(imageWidth - overlaySize)), 0),
                y: Math.min(Math.max(canvasPosition.y + difY / 2, -(imageHeight - overlaySize)), 0),
            });
        }

    // eslint-disable-next-line
    }, [originalImageSize, ratio, scale]);

    useEffect(() => {
        if (!originalImageSize.width > 0 || !originalImageSize.height > 0) return;
        const ratio = originalImageSize.height < originalImageSize.width ? overlaySize / originalImageSize.height : overlaySize / originalImageSize.width;
        setRatio(ratio);

    // eslint-disable-next-line
    }, [originalImageSize]);

    useEffect(() => {

        if (!imageObject) return;

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.canvas.height = overlaySize;
        context.canvas.width = overlaySize;

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(imageObject.image, canvasPosition.x, canvasPosition.y, imageSize.width, imageSize.height);

        const data = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        (onChange ? onChange : () => {})(data);

    // eslint-disable-next-line
    }, [canvasPosition, scale]);

    useEffect(() => {

        const onMouseMove = (event) => {

            if (!draggingCanvas) return;
            const bcr = imageWrapperRef.current.getBoundingClientRect();

            const canvasPosX = Math.min(Math.max(canvasOffset.x + (event.clientX - bcr.left), -(imageSize.width - bcr.width)), 0);
            const canvasPosY = Math.min(Math.max(canvasOffset.y + (event.clientY - bcr.top), -(imageSize.height - bcr.height)), 0);

            setCanvasPosition({
                x: canvasPosX,
                y: canvasPosY
            });
            
        }

        const onMouseUp = (event) => setDraggingCanvas(false);

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        }

    // eslint-disable-next-line
    }, [draggingCanvas]);
    
    return (
        <div className={[styles.container, className].join(' ').trim()}>
            <div className={styles.cropper} ref={areaRef} onMouseDown={(event) => {
                const bcr = imageWrapperRef.current.getBoundingClientRect();
                setDraggingCanvas(true);
                setCanvasOffset({
                    x: canvasPosition.x - (event.clientX - bcr.left),
                    y: canvasPosition.y - (event.clientY - bcr.top)
                });
            }}>
                <div className={styles.wrapper} ref={imageWrapperRef}>
                    <img alt={''} ref={imageRef} src={imageObject ? imageObject.data : ''} style={{
                        width: imageSize.width,
                        height: imageSize.height,
                        left: `${canvasPosition.x}px`,
                        top: `${canvasPosition.y}px`,
                    }}/>
                </div>
                <div className={styles['overlay-wrapper']} ref={overlayRef}>
                    <div className={styles.overlay}/>
                </div>
            </div>
            <Slider className={styles.slider} onChange={value => setScale(1 + (value / 100))} value={0}/>
        </div>
    )
}

export default Cropper;