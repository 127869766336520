import { useEffect, useState } from 'react';
import styles from '../../../assets/css/dialog.module.css';
import { useStore } from '../../../assets/js/Store';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import Loader from '../../../components/Loader';
import { fetchConfirmEmailStatus } from '../../../assets/js/Api';

const timeMapping = {
    SECOND: 1,
    MINUTE: 1 * 60,
    HOURS : 1 * 60 * 60,
    DAYS  : 1 * 60 * 60 * 24
}

export const ConfirmEmail = (props) => {

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const close = useStore((state) => state.closeDialog);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [emailStatus, setEmailStatus] = useState(null);

    const [time, setTime] = useState(null);

    useEffect(() => {

        const update = () => {

            if (!emailStatus || !emailStatus.data || !emailStatus.data.expires_on) return;

            let dif = Math.floor(((new Date(emailStatus.data.expires_on).getTime()) - new Date().getTime()) / 1000);
            if (dif < 0){
                setTime(null);
                setDisabled(false);
                return;
            }
            setDisabled(true);

            const days = Math.floor(dif / timeMapping.DAYS);
            dif %= timeMapping.DAYS;
            const hours = Math.floor(dif / timeMapping.HOURS);
            dif %= timeMapping.HOURS;
            const minutes = Math.floor(dif / timeMapping.MINUTE);
            dif %= timeMapping.MINUTE;
    
            if (days === 0 && hours === 0 && minutes === 0){

                setTime(`${dif} second${dif !== 1 ? 's' : ''}`);

            } else if (days === 0 && hours === 0) {

                setTime(`${minutes} minute${minutes !== 1 ? 's' : ''}`);

            } else if (days === 0) {

                setTime(`${hours} hour${hours !== 1 ? 's' : ''}`);

            } else {

                setTime(`${days} day${days !== 1 ? 's' : ''}`);

            }

        }

        const i = setInterval(() => {
            update();
        }, 500);
        update();

        return () => clearInterval(i);

    }, [emailStatus])

    useEffect(() => {

        if (props.close !== undefined) setCloseDialog(props.close);

    }, [setCloseDialog, close, props.close]);

    useEffect(() => {

        fetchConfirmEmailStatus().then(res => {
            setEmailStatus(res);
        });

    }, []);

    return (
        <div className={styles.dialog}>
            { emailStatus && emailStatus.status === 200 ? <>
                <h2>{`Confirm E-Mail`}</h2>
                <p>{'We\'ve sent you an email with a link to confirm your email address.'}</p>
                { time ? <>
                    <p>{`Link expires in ${time}.`}</p>
                </> : <>
                    <p>{`Link has expired.`}</p>
                </>}
                <div className={styles['button-tray']}>
                    <SecondaryButton className={styles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)}/>
                    <PrimaryButton loading={loading} disabled={disabled} className={styles.button} label={'Resend'} onClick={(event) => {
                        setLoading(true);
                        fetchConfirmEmailStatus().then(res => {
                            setEmailStatus(res);
                            setLoading(false);
                        });
                    }}/>
                </div>
            </> : <>
                <Loader size={40} color={'black'} className={styles.loader}/>
            </>}
            
        </div>
    )

}

export default ConfirmEmail;