import { forwardRef, useRef } from 'react';
import styles from '../assets/css/views/login.module.css';
import Input from './Input';

export const SecurityCode = forwardRef(({onVerify, onFocus, error, disabled, onChange}, ref) => {

    const containerRef = useRef(null);

    const handleKeyDown = (e) => {

        if (!/[0-9]|Backspace|Tab|Enter|ArrowLeft|ArrowRight/.test(e.key)){
            if (!(e.ctrlKey && e.key === 'v')){
                e.preventDefault();
                return;
            }
        } else if (/Tab/.test(e.key)) return;

        const inputs = Array.from(e.target.parentElement.parentElement.parentElement.getElementsByTagName('input'));
        const current = e.target;
        const previous = inputs[inputs.indexOf(current) - 1];
        const next = inputs[inputs.indexOf(current) + 1];

        setTimeout(() => {

            const newValue = e.target.value;
            if (newValue.length === 1 || e.key === 'ArrowRight'){

                if (next)
                    next.focus();
                else {
                    current.blur();
                    verify();
                }

            } else if (newValue.length === 0 || e.key === 'ArrowLeft') if (previous) previous.focus();

        }, 5);
    }

    const handlePaste = (e) => {

        e.preventDefault();
        const text = e.clipboardData.getData('Text');
        if (text.length === 6){

            const inputs = Array.from(containerRef.current.getElementsByTagName('input'));
            inputs[0].value = text[0];
            inputs[1].value = text[1];
            inputs[2].value = text[2];
            inputs[3].value = text[3];
            inputs[4].value = text[4];
            inputs[5].value = text[5];
            e.target.blur();

            verify();

        }

    }

    const verify = () => {

        const inputs = Array.from(containerRef.current.getElementsByTagName('input'));
        const code = `${inputs[0].value}${inputs[1].value}${inputs[2].value}${inputs[3].value}${inputs[4].value}${inputs[5].value}`;
        (onVerify ? onVerify : () => {})(code);

    }

    const change = () => {

        const inputs = Array.from(containerRef.current.getElementsByTagName('input'));
        const code = `${inputs[0].value}${inputs[1].value}${inputs[2].value}${inputs[3].value}${inputs[4].value}${inputs[5].value}`;
        (onChange ? onChange : () => {})(code);

    }

    return (
        <div className={styles.tfa} ref={containerRef}>
            <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={disabled} onPaste={handlePaste} onFocus={onFocus} onChange={change}/>
            <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={disabled} onPaste={handlePaste} onFocus={onFocus} onChange={change} />
            <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={disabled} onPaste={handlePaste} onFocus={onFocus} onChange={change} />
            <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={disabled} onPaste={handlePaste} onFocus={onFocus} onChange={change} />
            <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={disabled} onPaste={handlePaste} onFocus={onFocus} onChange={change} />
            <Input error={error} inputMode={'numeric'} className={styles['tfa-code']} maxLength={1} onKeyDown={handleKeyDown} disabled={disabled} onPaste={handlePaste} onFocus={onFocus} onChange={change} />
        </div>
    )
});

export default SecurityCode;