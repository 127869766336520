import styles from '../../assets/css/views/login.module.css';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';

import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIdentity, useAuthScreen, authorizeOAuthApp } from '../../assets/js/Api';
import Scopes, { Descriptions, Icons } from '../../assets/js/Scopes';
import Loader from '../../components/Loader';

export const Authorize = (props) => {

    const [permissionsOverflow, setPermissionsOverflow] = useState(false);
    const [searchParams] = useSearchParams();
    const authorizationScreen = useAuthScreen(searchParams.get('client_id'), searchParams.get('response_type'), searchParams.get('redirect_uri'), searchParams.get('scope'));
    const identity = useIdentity();

    const handleOverflow = useCallback(ref => {
        
        if (!ref) return;

        const handler = () => {
            const scrollTotal = ref.scrollHeight - ref.getBoundingClientRect().height;
            const scrollTop = ref.scrollTop;
            setPermissionsOverflow(scrollTotal - scrollTop > 0);
        };

        ref.addEventListener('scroll', handler);
        handler();

    }, [])

    if (!identity || !authorizationScreen) return <div>
        <Loader size={40} color={'black'} className={styles['page-loader']}/>
    </div>;

    return <>
        {authorizationScreen && authorizationScreen.client ? <>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <img className={styles.logo} alt="" src="https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg" />
                    <h1 className={styles.title}>{authorizationScreen ? authorizationScreen.client.name : 'Client Name'}</h1>
                    <div className={styles.subtitle}>{"is requesting access to your Xenfuma Account"}</div>
                    <div className={styles.profile}>
                        <img className={styles.icon} alt="" src={`https://cdn.xenfuma.com/avatars/${identity.avatar}.png`} />
                        <div className={styles.email}>{identity.email}</div>
                    </div>
                    <div className={styles.permissions}>
                        <div className={styles.label}>{"This will allow Messenger to:"}</div>
                        <div className={[styles.list, permissionsOverflow ? styles.overflow : null].join(' ').trim()} ref={handleOverflow}>
                            {(authorizationScreen ? authorizationScreen.scopes : []).map((s, i) => {

                                const scope = Object.keys(Scopes).find(key => Scopes[key] === s);

                                return (
                                    <div className={styles.permission} key={i}>
                                        <div className={styles.icon}>
                                            {Icons[scope]}
                                        </div>
                                        <div className={styles.label}>{Descriptions[scope]}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <form>
                        <div className={styles['button-tray']}>
                            <PrimaryButton className={styles.button} label={"Allow"} onClick={() => {

                                authorizeOAuthApp(
                                    searchParams.get('client_id'), 
                                    searchParams.get('response_type'), 
                                    searchParams.get('redirect_uri'), 
                                    searchParams.get('scope'),
                                    searchParams.get('code_challenge'),
                                    searchParams.get('code_challenge_method'),
                                    true
                                ).then(res => {
                                    if (res.error){
                                        console.log(res)
                                    } else {
                                        window.location.href = `${res.data.redirect_uri}?code=${res.data.token}`;
                                    }
                                });

                            }} />
                            <SecondaryButton className={styles.button} label={"Cancel"} onClick={() => {

                                authorizeOAuthApp(
                                    searchParams.get('client_id'), 
                                    searchParams.get('response_type'), 
                                    searchParams.get('redirect_uri'), 
                                    searchParams.get('scope'),
                                    searchParams.get('code_challenge'),
                                    searchParams.get('code_challenge_method'),
                                    false
                                ).then(res => {
                                    window.location.href = `${res.data.redirect_uri}?error=${res.data.error}`;
                                });

                            }} />
                        </div>

                    </form>
                </div>
            </div>
        </> : <>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <img className={styles.logo} alt="" src="https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg" />
                    <h1 className={styles.title}>{"Error"}</h1>
                    <div className={[styles.subtitle, styles['slim-margin']].join(' ').trim()}>{authorizationScreen.error_description}</div>
                    <div className={styles['error-uri']}>
                        <a href={authorizationScreen.error_uri} rel="noreferrer" target='_blank'><span className={styles.clickable}>{"Open Docs"}</span></a>
                    </div>
                </div>
            </div>
        </>}
    </> 

}

export default Authorize;