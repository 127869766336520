import styles from '../../assets/css/views/login.module.css';

import Input from '../../components/Input';

import { PrimaryButton } from '../../components/Buttons';
import { Link, useNavigate } from 'react-router-dom';

export const Verify = (props) => {

    const navigate = useNavigate();

    return <div className={styles.container}>
        <div className={styles.wrapper}>
            <img className={styles.logo} alt="" src="https://accounts.xenfuma.com/filesystem/svg/xenfuma_logo_b.svg"/>
            <h1 className={styles.title}>{"Authorize"}</h1>
            <div className={styles.subtitle}>{"Verify your identity to continue with this action"}</div>
            <div className={styles.profile}>
                <img className={styles.icon} alt="" src={"https://cdn.xenfuma.com/avatars/LDR5eyz0RfelYeQlrobjf3U6h3eVNP0N.png"}/>
                <div className={styles.email}>{"marchauck04@gmail.com"}</div>
            </div>
            <form>
                <Input placeholder={"Password"} type={"password"}/>
                <div className={[styles['password-extra'], styles['single-item']].join(' ').trim()}>
                    <Link to={"/reset"}><div className={styles['forgot-password']}>{"Forgot password"}</div></Link>
                </div>
                <PrimaryButton className={styles.button} label={"Continue"} onClick={(e) => {
                    e.setLoading(true);
                    setTimeout(() => {
                        navigate('/')
                    }, 3000);
                }}/>
            </form>
            <div className={styles['sign-up']}>
                <span>{"Not your account?"}<Link to={"/logout"}><span className={styles.clickable}>{"Sign Out"}</span></Link></span>
            </div>
        </div>
    </div>

}

export default Verify;