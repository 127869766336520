import headerStyles from '../../assets/css/headers.module.css';
import { useDevices } from '../../assets/js/Api';
import { LoginDevice } from '../../components/LoginDevice';
import { Section } from '../../components/Section';

export const Devices = (props) => {

    const devices = useDevices();

    return <>
        <h1 className={[headerStyles['header-1'], headerStyles['no-bottom-margin']].join(' ').trim()}>{"Devices"}</h1>
        <p>{"These are all devices currently logged into your Xenfuma account"}</p>
        <Section>
            { (devices ? devices.sort((a,b) => b.current) : []).map((d, i) => {
                return <LoginDevice 
                    key={i} 
                    id={d.id}
                    thisDevice={d.current}
                    deviceName={d.meta.device_name} 
                    operatingSystem={d.meta.platform} 
                    browser={d.meta.browser} 
                    lastLogin={new Date(d.created_on)}
                    type={
                        d.meta.device_name || 
                        String(d.meta.platform).toLowerCase() === 'android' || 
                        String(d.meta.platform).toLowerCase() === 'ios' ? 'mobile' : ''}
                    removable
                />
            })}
        </Section>
    </>

}

export default Devices;