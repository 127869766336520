import styles from '../assets/css/buttons.module.css';
import Loader from './Loader';

export const PrimaryButton = (props) => {

    return <button type="button" className={[props.className, styles.button, props.loading ? styles.loading : null, props.disabled ? styles.disabled : null].join(' ').trim()} onClick={(event) => {
        if (props.disabled || props.loading) return;
        (props.onClick ? props.onClick : () => {})({
            initialEvent : event
        })
    }}>
        <div className={styles.contents}>
            {props.loading ? <>
                <Loader className={styles.loading}/>
            </> : null}
            <div className={styles.label}>{props.label}</div>
        </div>
    </button>

}

export const SecondaryButton = (props) => {

    return <button type="button" className={[props.className, styles.button, styles.secondary, props.loading ? styles.loading : null, props.disabled ? styles.disabled : null].join(' ').trim()} onClick={(event) => {
        if (props.disabled || props.loading) return;
        (props.onClick ? props.onClick : () => {})({
            initialEvent : event
        })
    }}>
        <div className={styles.contents}>
        {props.loading ? <>
                <Loader className={styles.loading}/>
            </> : null}
            <div className={styles.label}>{props.label}</div>
        </div>
    </button>

}

export const DangerButton = (props) => {

    return <button type="button" className={[props.className, styles.button, styles.danger, props.loading ? styles.loading : null, props.disabled ? styles.disabled : null].join(' ').trim()} onClick={(event) => {
        if (props.disabled || props.loading) return;
        (props.onClick ? props.onClick : () => {})({
            initialEvent : event
        })
    }}>
        <div className={styles.contents}>
        {props.loading ? <>
                <Loader className={styles.loading}/>
            </> : null}
            <div className={styles.label}>{props.label}</div>
        </div>
    </button>

}