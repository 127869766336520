import { useState } from 'react';
import styles from '../assets/css/checkbox.module.css';

import Check from '../assets/svgs/Check';

export const Checkbox = (props) => {

    const [value, setValue] = useState(props.value);

    return (
        <>
            <div className={[styles['checkbox-wrapper'], value ? styles.checked : null].join(' ').trim()}>
                <div className={styles.checkbox}>
                    <input onChange={(event) => { setValue(event.target.checked); (props.onChange ? props.onChange : () => {})(event.target.checked) }} type={'checkbox'} className={[styles.checkbox, props.error ? styles.error : null].join(' ').trim()}/>
                    <Check/>
                </div>

                <div className={styles.label}>{props.label ? props.label : ''}</div>
            
            </div>
        </>
    )

}

export default Checkbox;